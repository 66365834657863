import { useInView } from "react-intersection-observer";
import styled from "styled-components";
import ring from "../../image/ring.png";
import diffferentSales from "../../image/diffferentSales.jpg";
import aiServiceImage from "../../image/mdAi.png"
import cfoSupportImage from "../../image/mdCfoSupport.png"

const HomeServiceStyle = styled.div`
  margin-bottom: 300px;
  .service-head {
    display: flex;
    align-items: center;
    p {
      margin: 0;
      margin-left: 5px;
    }
  }
  .ring {
    img {
      width: 10px;
    }
  }
  .service-title {
    display: flex;
    align-items: center;
    padding-left: 50px;
  }
  .title-text {
    white-space: nowrap;
    margin: 0 30px;
    padding-top: 5px;
  }
  .white-title-not-inview {
    font-size: 12rem;
    font-weight: lighter;
    white-space: nowrap;
    color: white;
    overflow: hidden;
    p {
      margin: 0;
    }
  }
  .white-title {
    font-size: 12rem;
    font-weight: lighter;
    white-space: nowrap;
    color: rgb(239, 238, 238);
    overflow: hidden;
    p {
      margin: 0;
      animation: service;
      animation-duration: 2s;
    }
  }
  @keyframes service {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  }
  ul {
    padding-left: 0;
  }
  ul li:nth-child(even) {
    flex-direction: row-reverse;
  }
  .service-content {
    display: flex;
    justify-content: center;
    margin: 75px auto;
  }
  .service-content:first-child {
    margin-top: 0;
  }
  .service-image {
    display: flex;
    align-items: center;
    width: 45%;
  }
  .service-image img {
    max-width: 100%;
  }
  .service-content-text {
    width: 50%;
  }
  ul li:nth-child(odd) .text-container {
    padding-left: 100px;
  }
  ul li:nth-child(even) .text-container {
    padding-right: 100px;
  }
  .text-slider {
    font-size: 12rem;
    font-weight: lighter;
    white-space: nowrap;
    display: flex;
    overflow: hidden;
    color: orange;
    p {
      line-height: 100%;
      margin: 0;
      &:first-child {
        animation: textslider1 30s -15s linear infinite;
      }
      &:last-child {
        animation: textslider2 30s linear infinite;
      }
    }
  }
  .text-slider2 {
    p {
      margin-bottom: 100px;
      &:first-child {
        animation-direction: reverse;
      }
      &:last-child {
        animation-direction: reverse;
      }
    }
  }
  @keyframes textslider1 {
    0% {
      transform: translateX(100%);
    }
    to {
      transform: translateX(-100%);
    }
  }
  @keyframes textslider2 {
    0% {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-200%);
    }
  }
  .view-more {
    margin-top: 50px;
    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: black;
      padding: 0 5px;
    }
    img {
      max-width: 15px;
      margin: 0;
      display: block;
    }
  }
  .arrow-container {
    background-color: black;
    padding: 5px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin: auto 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .view-more:hover {
    a {
      padding: 0;
    }
    .arrow-container {
      height: 45px;
      width: 45px;
    }
  }
  h2 {
    white-space: pre-wrap;
    line-height: 1.5em;
  }
  .service-text-paragraph {
    white-space: pre-wrap;
    width: 100%;
  }
  @media screen and (max-width: 900px) {
    .service-title {
      display: block;
      .title-text {
        margin-left: 0;
      }
    }
    .text-container {
      p {
        width: 100%;
      }
    }
    ul li:nth-child(odd) .text-container {
      padding-left: 50px;
    }
    ul li:nth-child(even) .text-container {
      padding-right: 50px;
    }
  }
  @media screen and (max-width: 600px) {
    .service-content {
      display: block;
    }
    .service-image {
      margin: auto;
      width: 70%;
      img {
        width: 100%;
        height: auto;
      }
    }
    .service-content-text {
      margin: auto;
      width: 70%;
    }
    ul li:nth-child(odd) .text-container {
      padding-left: 0;
    }
    ul li:nth-child(even) .text-container {
      padding-right: 0;
    }
  }
`;

export const HomeService = () => {
  const service = [
    {
      h3: "Service1",
      h2: "diffferent sales\n〜機械学習×キーエンス式営業ノウハウ〜",
      p: "「diffferent sales」はクライアントの売上に最も起因するアポイントの質を改善するAIサービスになります。企業の売上を向上するためにはじめに改善しないといけないのは、アポイントの量と質です。”決裁者”へのアポイント率を改善できれば必然的に売上改善につながりますが、社内で解決しようとすると時間とお金がかかります。社外に外注しようとすると、現状この問題をダイレクトに解決できる方法はありませんでした。そこで我々はAIを用いて”決裁者”に直接アポイントを獲得するシステムを開発し、クライアントの売上向上に貢献しています。",
      image: diffferentSales,
    },
    {
      h3: "Service2",
      h2: "AI Development",
      p: "今までのAIを活用したサービス開発の知見をもとに、AIを活用した新規サービスの開発受託や、オペレーションの最適化等を提供いたします。過去事例として「AIが人のように対話するコールセンター」「既存CRMのデータを基に最適なアプローチ先の割出や受注予測」「自動走行機器の最適制御」など、営業にまつわることから、製造業/物流業の省人化まで幅広くクライアント様の利益率向上に貢献しております。",
      image: aiServiceImage,
    },
    {
      h3: "Service3",
      h2: "CFO Support",
      p: "元政府系バンカーであり数々のベンチャー企業のCFOを歴任した弊社CFOによる資金調達支援サービスを提供いたします。金融機関様からのご融資を断られてしまった企業様にも様々な種類のご提案をさせていただき、調達実施まで伴走いたします。また投資家様、金融機関様、M&A企業様との提携も多数ございますので、資金調達全般のご支援が可能でございます。",
      image: cfoSupportImage,
    },
  ];

  const { ref, inView } = useInView();
  return (
    <HomeServiceStyle id="service">
      <div className="service-title">
        <div className="service-head">
          <div className="ring">
            <img src={ring} alt="ring" />
          </div>
          <p className="title">Service</p>
        </div>
        <p className="title-text">
          AIを最大限活用し3つのサービスをおこなっています。
        </p>
        <div
          ref={ref}
          className={inView ? "white-title" : "white-title-not-inview"}
        >
          <p>Service</p>
        </div>
      </div>
      <ul className="service-list">
        {service.map((e) => {
          return (
            <li key={e.h3} className="service-content">
              <div className="service-image">
                <img src={e.image} alt="bird" />
              </div>
              <div className="service-content-text">
                <div className="text-container">
                  <h3>{e.h3}</h3>
                  <h2>{e.h2}</h2>
                  <p className="service-text-paragraph">{e.p}</p>
                  {/* <div className="view-more">
                      <Link to={"/service/"}>
                        <p>view more</p>
                        <div className="arrow-container">
                          <img src={arrow} alt="arrow" />
                        </div>
                      </Link>
                    </div> */}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
      <div className="text-slider">
        <p>&nbsp;AI&nbsp; SalesTech&nbsp; FAS&nbsp; M&A&nbsp;</p>
        <p>&nbsp;AI&nbsp; SalesTech&nbsp; FAS&nbsp; M&A&nbsp;</p>
      </div>
      <div className="text-slider text-slider2">
        <p>&nbsp;AI&nbsp; SalesTech&nbsp; FAS&nbsp; M&A&nbsp;</p>
        <p>&nbsp;AI&nbsp; SalesTech&nbsp; FAS&nbsp; M&A&nbsp;</p>
      </div>
    </HomeServiceStyle>
  );
};
